/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || 
  (process.env.NODE_ENV === 'production' ? `wss://${window.location.host}` : '');

import { useEffect, useRef, useCallback, useState } from 'react';
// import { Helmet } from 'react-helmet'; // You may need to install this package

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';

import { X, Edit, Zap, ArrowUp, ArrowDown } from 'react-feather';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';

import './ConsolePage.scss';

import { handleFileSubmission } from '../services/openaiAssistant';
import { setupResourceSuggestionTool, generateSuggestedResources } from '../services/resourceSuggestion';

/** Define the RealtimeEvent interface */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {
  /**
   * Ask user for API Key
   * If we're using the local relay server, we don't need this
   */
  // Remove or comment out this block
  // const apiKey = LOCAL_RELAY_SERVER_URL
  //   ? ''
  //   : localStorage.getItem('tmp::voice_api_key') ||
  //     prompt('OpenAI API Key') ||
  //     '';
  // if (apiKey !== '') {
  //   localStorage.setItem('tmp::voice_api_key', apiKey);
  // }

  // Instead, use:
  const apiKey = ''; // We'll rely on the server-side API key

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { 
            url: process.env.NODE_ENV === 'production' 
              ? `wss://${window.location.host}/ws`
              : LOCAL_RELAY_SERVER_URL 
          }
        : {
            apiKey: apiKey,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - resources is for suggested resources
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [resources, setResources] = useState<
    { title: string; url: string; description?: string }[]
  >([]);

  /**
   * State variables for file upload and processing
   */
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [isFileSubmitted, setIsFileSubmitted] = useState(false);
  const fileContentRef = useRef<string | null>(null);

  // Add this new state to store the conversation context
  const [conversationContext, setConversationContext] = useState<string>('');

  // Add this new state to track if we need to generate new suggestions
  const [shouldGenerateSuggestions, setShouldGenerateSuggestions] = useState(false);

  // Add this new state to track if we're currently recording
  const [isCurrentlyRecording, setIsCurrentlyRecording] = useState(false);

  // Refs for state variables
  const conversationContextRef = useRef(conversationContext);
  const shouldGenerateSuggestionsRef = useRef(shouldGenerateSuggestions);

  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    fileContentRef.current = fileContent;
  }, [fileContent]);

  // Update refs when state changes
  useEffect(() => {
    conversationContextRef.current = conversationContext;
  }, [conversationContext]);

  useEffect(() => {
    shouldGenerateSuggestionsRef.current = shouldGenerateSuggestions;
  }, [shouldGenerateSuggestions]);

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * When you click the API key
   */
  const resetAPIKey = useCallback(() => {
    const apiKey = prompt('OpenAI API Key');
    if (apiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', apiKey);
      window.location.reload();
    }
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder takes speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    try {
      // Set state variables
      startTimeRef.current = new Date().toISOString();
      setRealtimeEvents([]);
      setItems(client.conversation.getItems());

      // Connect to microphone
      await wavRecorder.begin();

      // Connect to audio output
      await wavStreamPlayer.connect();

      // Connect to realtime API
      await client.connect();

      // Set connected state after successful connection
      setIsConnected(true);

      // Send initial message
      client.sendUserMessageContent([
        {
          type: 'input_text',
          text: 'Hello! Can you help me with fitness and health advice?',
        },
      ]);

      // Start recording only after connection is established
      if (client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => {
          if (client.isConnected()) {
            client.appendInputAudio(data.mono);
          } else {
            console.error('Client disconnected during recording');
            wavRecorder.pause();
          }
        });
      }
    } catch (error) {
      console.error('Failed to connect:', error);
      setIsConnected(false);
      // Display user-friendly error message
      alert('Failed to connect. Please try again later.');
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setIsRecording(false);
    setRealtimeEvents([]);
    setItems([]);
    setResources([]);
    setUploadedFile(null);
    setFileContent(null);
    setIsFileSubmitted(false);

    const client = clientRef.current;
    await client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    setIsCurrentlyRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    let isPausing = false;

    try {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
      await wavRecorder.record((data) => {
        if (client.isConnected()) {
          client.appendInputAudio(data.mono);
        } else if (!isPausing) {
          console.error('Client disconnected during recording');
          isPausing = true;
          if (wavRecorder.getStatus() === 'recording') {
            wavRecorder.pause().catch(error => {
              console.error('Error pausing recorder:', error);
            }).finally(() => {
              setIsRecording(false);
              setIsCurrentlyRecording(false);
              isPausing = false;
            });
          } else {
            console.log('Recorder is not in recording state, skipping pause');
            setIsRecording(false);
            setIsCurrentlyRecording(false);
            isPausing = false;
          }
        }
      });
    } catch (error) {
      console.error('Error starting recording:', error);
      setIsRecording(false);
      setIsCurrentlyRecording(false);
    }
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    if (!isCurrentlyRecording) {
      console.log('Not currently recording, no need to stop');
      return;
    }

    console.log('Stopping recording...');
    setIsRecording(false);
    setIsCurrentlyRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    try {
      await wavRecorder.pause();
      if (client.isConnected()) {
        client.createResponse();
        console.log('Recording stopped and response created');
      } else {
        console.log('Recording stopped, but client is disconnected');
      }
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await startRecording();
    }
    setCanPushToTalk(value === 'none');
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Handle file upload
   */
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setUploadedFile(file);
      setIsFileSubmitted(false);
    }
  };

  /**
   * Handle file submission
   */
  async function handleFileSubmit() {
    if (!uploadedFile) {
      alert('Please upload a file before submitting.');
      return;
    }

    if (!isConnected) {
      alert('Please connect to the Realtime API before submitting a file.');
      return;
    }

    try {
      const client = clientRef.current;
      const summary = await handleFileSubmission(uploadedFile, apiKey, client);
      setIsFileSubmitted(true);

      // Update the conversation context with the file summary
      const updatedContext = `${conversationContext}\nFile Summary: ${summary}`;
      setConversationContext(updatedContext);

      // Set the flag to generate suggestions after file upload
      setShouldGenerateSuggestions(true);

      // Send the summary to the real-time API
      client.sendUserMessageContent([
        {
          type: 'input_text',
          text: summary,
        },
      ]);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while processing the file. Please check the console for details.');
    }
  }

  // Add this new function to handle user input
  const handleUserInput = (input: string) => {
    console.log('Handling user input:', input);
    const client = clientRef.current;
    if (!client.isConnected()) {
      console.error('Cannot send message: RealtimeAPI is not connected');
      return;
    }
    console.log('Client is connected, sending message');
    client.sendUserMessageContent([
      {
        type: 'input_text',
        text: input,
      },
    ]);
    console.log('User message sent, waiting for response...');
    setShouldGenerateSuggestions(true);
  };

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    const client = clientRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set instructions
    const instructions = `
      You are a helpful assistant that specializes in fitness and health.
      The user may have provided a summary of a fitness or health-related file. Please use this summary to provide insights, advice, or further information that could help the user.
      Please focus on providing useful information based on the conversation and any provided file summary.
    `;
    console.log('Setting instructions:', instructions);
    client.updateSession({ instructions: instructions });

    // Set transcription
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    // Set up the resource suggestion tool
    setupResourceSuggestionTool(client);

    // Set up event handling
    client.on('conversation.updated', async ({ item, delta }: { item: any, delta: any }) => {
      console.log('Conversation updated:', item, delta);
      const items = client.conversation.getItems();
      console.log('All conversation items:', items);

      if (item) {
        console.log('Item role:', item.role);
        console.log('Item status:', item.status);
        console.log('Item content:', item.content);
      }

      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        try {
          const wavFile = await WavRecorder.decode(
            item.formatted.audio,
            24000,
            24000
          );
          item.formatted.file = wavFile;
        } catch (error) {
          console.error('Error decoding audio:', error);
        }
      }
      setItems(items);

      // Update the conversation context
      const updatedContext = items.reduce((context, item) => {
        if (item.role === 'user' || item.role === 'assistant') {
          return `${context}\n${item.role}: ${item.formatted.text || ''}`;
        }
        return context;
      }, conversationContextRef.current);
      setConversationContext(updatedContext);

      // Generate new resource suggestions only if the flag is set
      if (
        shouldGenerateSuggestionsRef.current &&
        item.role === 'assistant' &&
        item.status === 'completed'
      ) {
        try {
          const newResources = await generateSuggestedResources(client, updatedContext);
          setResources(newResources);
          setShouldGenerateSuggestions(false);  // Reset the flag
        } catch (error) {
          console.error('Error generating resources:', error);
          // Handle the error appropriately (e.g., show a message to the user)
        }
      }
    });

    client.on('error', (event: any) => {
      console.error('RealtimeAPI error:', event);
    });

    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    client.on('response.content.delta', (event: any) => {
      console.log('Response content delta received:', event);
    });

    client.on('connection.status.changed', (status: string) => {
      console.log('Connection status changed:', status);
      if (status === 'disconnected') {
        setIsConnected(false);
        setIsRecording(false);
        setIsCurrentlyRecording(false);
      }
    });

    return () => {
      // Remove event listeners individually
      client.off('conversation.updated');
      client.off('error');
      client.off('conversation.interrupted');
      client.off('response.content.delta');
      client.off('connection.status.changed');

      // Reset client to defaults
      client.reset();
    };
  }, []); // Empty dependency array

  // Use separate useEffect for generating resource suggestions
  useEffect(() => {
    if (shouldGenerateSuggestions && isConnected) {
      (async () => {
        try {
          const newResources = await generateSuggestedResources(
            clientRef.current,
            conversationContext
          );
          setResources(newResources);
          setShouldGenerateSuggestions(false);
        } catch (error) {
          console.error('Error generating resources:', error);
        }
      })();
    }
  }, [conversationContext, shouldGenerateSuggestions, isConnected]);

  // Replace console.log with a custom logger function
  const log = (message: string, ...args: any[]) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(message, ...args);
    }
  };

  useEffect(() => {
    const recorder = wavRecorderRef.current;
    return () => {
      if (recorder) {
        recorder.end().catch(console.error);
      }
    };
  }, [recorder]);

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <div className="content-top">
        <div className="content-title">
          <img src="/openai-logomark.svg" alt="OpenAI Logo" /> {/* Using OpenAI logo */}
          <span>P2 Realtime Assistant</span>
        </div>
        <div className="content-api-key">
          {/* Remove or modify this block */}
          {/* <Button
            icon={Edit}
            iconPosition="end"
            buttonStyle="flush"
            label={`api key: ${apiKey.slice(0, 3)}...`}
            onClick={() => resetAPIKey()}            /> */}
        </div>
      </div>
      <div className="content-main">
        <div className="content-logs">
          <div className="content-block events">
            <div className="visualization">
              <div className="visualization-entry client">
                <canvas ref={clientCanvasRef} />
              </div>
              <div className="visualization-entry server">
                <canvas ref={serverCanvasRef} />
              </div>
            </div>
            <div className="content-block-title">events</div>
            <div className="content-block-body" ref={eventsScrollRef}>
              {!realtimeEvents.length && `awaiting connection...`}
              {realtimeEvents.map((realtimeEvent, i) => {
                const count = realtimeEvent.count;
                const event = { ...realtimeEvent.event };
                if (event.type === 'input_audio_buffer.append') {
                  event.audio = `[trimmed: ${event.audio.length} bytes]`;
                } else if (event.type === 'response.audio.delta') {
                  event.delta = `[trimmed: ${event.delta.length} bytes]`;
                }
                return (
                  <div className="event" key={event.event_id}>
                    <div className="event-timestamp">
                      {formatTime(realtimeEvent.time)}
                    </div>
                    <div className="event-details">
                      <div
                        className="event-summary"
                        onClick={() => {
                          // toggle event details
                          const id = event.event_id;
                          const expanded = { ...expandedEvents };
                          if (expanded[id]) {
                            delete expanded[id];
                          } else {
                            expanded[id] = true;
                          }
                          setExpandedEvents(expanded);
                        }}
                      >
                        <div
                          className={`event-source ${
                            event.type === 'error' ? 'error' : realtimeEvent.source
                          }`}
                        >
                          {realtimeEvent.source === 'client' ? (
                            <ArrowUp />
                          ) : (
                            <ArrowDown />
                          )}
                          <span>
                            {event.type === 'error'
                              ? 'error!'
                              : realtimeEvent.source}
                          </span>
                        </div>
                        <div className="event-type">
                          {event.type}
                          {count && ` (${count})`}
                        </div>
                      </div>
                      {!!expandedEvents[event.event_id] && (
                        <div className="event-payload">
                          {JSON.stringify(event, null, 2)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="content-block conversation">
            <div className="content-block-title">conversation</div>
            <div className="content-block-body" data-conversation-content>
              {!items.length && `awaiting connection...`}
              {items.map((conversationItem, i) => {
                return (
                  <div className="conversation-item" key={conversationItem.id}>
                    <div className={`speaker ${conversationItem.role || ''}`}>
                      <div>
                        {(
                          conversationItem.role || conversationItem.type
                        ).replaceAll('_', ' ')}
                      </div>
                      <div
                        className="close"
                        onClick={() =>
                          deleteConversationItem(conversationItem.id)
                        }
                      >
                        <X />
                      </div>
                    </div>
                    <div className={`speaker-content`}>
                      {/* tool response */}
                      {conversationItem.type === 'function_call_output' && (
                        <div>
                          {JSON.stringify(conversationItem.formatted.output)}
                        </div>
                      )}
                      {/* tool call */}
                      {!!conversationItem.formatted.tool && (
                        <div>
                          {conversationItem.formatted.tool.name}(
                          {conversationItem.formatted.tool.arguments})
                        </div>
                      )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'user' && (
                          <div>
                            {conversationItem.formatted.transcript ||
                              (conversationItem.formatted.audio?.length
                                ? '(awaiting transcript)'
                                : conversationItem.formatted.text ||
                                  '(item sent)')}
                          </div>
                        )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'assistant' && (
                          <div>
                            {conversationItem.formatted.transcript ||
                              conversationItem.formatted.text ||
                              '(truncated)'}
                          </div>
                        )}
                      {conversationItem.formatted.file && (
                        <audio
                          src={conversationItem.formatted.file.url}
                          controls
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* Move the user input above the toggle switch */}
          <div className="user-input-container">
            <input
              type="text"
              className="user-input"
              placeholder={isConnected ? "Start typing here..." : "Connect to chat...or start typing here"}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && isConnected) {
                  handleUserInput(e.currentTarget.value);
                  e.currentTarget.value = '';
                }
              }}
              disabled={!isConnected}
            />
          </div>
          <div className="content-actions">
            <Toggle
              defaultValue={false}
              labels={['manual', 'vad']}
              values={['none', 'server_vad']}
              onChange={(_, value) => changeTurnEndType(value)}
            />
            <div className="spacer" />
            {isConnected && canPushToTalk && (
              <Button
                label={isCurrentlyRecording ? 'release to send' : 'push to talk'}
                buttonStyle={isCurrentlyRecording ? 'alert' : 'regular'}
                disabled={!isConnected || !canPushToTalk}
                onMouseDown={startRecording}
                onMouseUp={stopRecording}
              />
            )}
            <div className="spacer" />
            <Button
              label={isConnected ? 'disconnect' : 'connect'}
              iconPosition={isConnected ? 'end' : 'start'}
              icon={isConnected ? X : Zap}
              buttonStyle={isConnected ? 'regular' : 'action'}
              onClick={
                isConnected ? disconnectConversation : connectConversation
              }
            />
          </div>
        </div>
        <div className="content-right">
          {/* File Upload Component */}
          <div className="content-block upload">
            <div className="content-block-title">
              Upload Your Fitness/Health File
            </div>
            <div className="content-block-body">
              <div className="file-input-wrapper">
                <button 
                  className="file-input-button" 
                  onClick={() => document.getElementById('file-input')?.click()}
                >
                  Choose File
                </button>
                <input
                  id="file-input"
                  type="file"
                  accept=".pdf,.txt,.docx,.doc,.xlsx,.xls"
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />
                <div className="file-name">
                  {uploadedFile ? uploadedFile.name : 'No file selected'}
                </div>
              </div>
              <button
                className="submit-button"
                disabled={!uploadedFile || isFileSubmitted || !isConnected}
                onClick={handleFileSubmit}
              >
                Submit File
              </button>
            </div>
          </div>

          {/* Resources Widget */}
          <div className="content-block resources">
            <div className="content-block-title">Suggested Resources</div>
            <div className="content-block-body">
              {resources.length === 0 && (
                <div>No resources suggested yet.</div>
              )}
              {resources.map((resource, index) => (
                <div key={index} className="resource">
                  <a
                    href={resource.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {resource.title}
                  </a>
                  {resource.description && <p>{resource.description}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}