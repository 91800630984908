import { RealtimeClient } from '@openai/realtime-api-beta';
import OpenAI from 'openai';

export async function handleFileSubmission(file: File, apiKey: string, client: RealtimeClient): Promise<string> {
  if (!client.isConnected()) {
    throw new Error('RealtimeAPI is not connected');
  }

  const openai = new OpenAI({ 
    apiKey,
    dangerouslyAllowBrowser: true
  });

  try {
    // Upload file to OpenAI
    const uploadedFile = await openai.files.create({
      file: file,
      purpose: 'assistants',
    });

    // Create a thread
    const thread = await openai.beta.threads.create();

    // Add a message to the thread
    await openai.beta.threads.messages.create(thread.id, {
      content: `Please analyze this file and provide a summary/overview.`,
      role: 'user',
    });

    // Create a run
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: 'asst_S33h4wqeLWWLrZBvBY3U8tjd',
      additional_instructions: `Analyze the file with ID ${uploadedFile.id}`,
    });

    // Poll for completion
    let completedRun;
    while (true) {
      const runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      if (runStatus.status === 'completed') {
        completedRun = runStatus;
        break;
      } else if (runStatus.status === 'failed') {
        throw new Error('Run failed');
      }
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before polling again
    }

    // Retrieve the assistant's message
    const messages = await openai.beta.threads.messages.list(thread.id);
    const assistantMessage = messages.data.find(message => message.role === 'assistant');

    if (!assistantMessage || !assistantMessage.content[0] || assistantMessage.content[0].type !== 'text') {
      throw new Error('No valid assistant message found');
    }

    const summary = assistantMessage.content[0].text.value;

    // Send the summary to the realtime API for TTS
    client.sendUserMessageContent([
      {
        type: 'input_text',
        text: summary,
      },
    ]);

    return summary; // Return the summary
  } catch (error) {
    console.error('Error in handleFileSubmission:', error);
    throw error;
  }
}