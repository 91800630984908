import { RealtimeClient } from '@openai/realtime-api-beta';

interface Resource {
  title: string;
  url: string;
  description?: string;
}

export function setupResourceSuggestionTool(client: RealtimeClient) {
  client.addTool(
    {
      name: 'get_fitness_health_resources',
      description: 'Retrieves fitness, health, and supplement resources based on the conversation context.',
      parameters: {
        type: 'object',
        properties: {
          context: {
            type: 'string',
            description: 'The conversation context including file summaries and user queries',
          },
          limit: {
            type: 'number',
            description: 'The maximum number of resources to return',
          },
        },
        required: ['context'],
      },
    },
    async ({ context, limit = 5 }: { context: string; limit?: number }) => {
      // This is a mock API call. In a real-world scenario, you'd replace this with an actual API call.
      const mockApiCall = async (context: string, limit: number): Promise<Resource[]> => {
        // Simulating API delay
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Mock data based on common fitness, health, and supplement topics
        const mockResources: Resource[] = [
          {
            title: "Understanding Your Blood Work Results",
            url: "https://www.example.com/blood-work-guide",
            description: "Learn how to interpret common blood test results and what they mean for your health."
          },
          {
            title: "Top Supplements for Improving Blood Markers",
            url: "https://www.example.com/supplements-for-blood-health",
            description: "Discover evidence-based supplements that can help improve various blood markers."
          },
          {
            title: "Nutrition Strategies to Optimize Blood Health",
            url: "https://www.example.com/nutrition-for-blood-health",
            description: "Explore dietary approaches to enhance your blood work results naturally."
          },
          {
            title: "Exercise and Its Impact on Blood Markers",
            url: "https://www.example.com/exercise-blood-health",
            description: "Understand how different types of exercise can influence your blood test results."
          },
          {
            title: "Best Omega-3 Supplements for Heart Health",
            url: "https://www.example.com/shop/omega-3-supplements",
            description: "High-quality omega-3 supplements to support cardiovascular health and improve lipid profiles."
          },
          {
            title: "Vitamin D Supplements for Immune Function",
            url: "https://www.example.com/shop/vitamin-d",
            description: "Top-rated vitamin D supplements to boost immune function and bone health."
          }
        ];

        // Filter resources based on the context (in a real scenario, this would be done by a sophisticated API)
        const filteredResources = mockResources.filter(resource => 
          context.toLowerCase().split(' ').some(word => 
            resource.title.toLowerCase().includes(word) || 
            resource.description?.toLowerCase().includes(word)
          )
        );

        return filteredResources.slice(0, limit);
      };

      try {
        const resources = await mockApiCall(context, limit);
        return resources;
      } catch (error) {
        console.error('Error fetching health and fitness resources:', error);
        return [];
      }
    }
  );
}

export async function generateSuggestedResources(client: RealtimeClient, conversationContext: string): Promise<Resource[]> {
  return new Promise((resolve, reject) => {
    const toolCallMessage = `Please use the get_fitness_health_resources tool with the following context: ${conversationContext}`;
    
    client.sendUserMessageContent([
      {
        type: 'input_text',
        text: toolCallMessage,
      },
    ]);

    // We need to set up a listener for the assistant's response
    const handleConversationUpdate = ({ item }: { item: any }) => {
      if (item.role === 'assistant' && item.status === 'completed') {
        // Assuming the assistant's response includes the resources
        try {
          const resources = JSON.parse(item.formatted.text) as Resource[];
          resolve(resources);
        } catch (error) {
          console.error('Error parsing assistant response:', error);
          resolve([]);
        }
        // Remove the listener after we've processed the response
        client.off('conversation.updated', handleConversationUpdate);
      }
    };

    client.on('conversation.updated', handleConversationUpdate);

    // Set a timeout in case we don't get a response
    setTimeout(() => {
      client.off('conversation.updated', handleConversationUpdate);
      reject(new Error('Timeout waiting for resource suggestions'));
    }, 30000); // 30 second timeout
  });
}